<template>
    <ul v-if="links.length" class="social-links">
        <li v-for="(social, index) in links" :key="index" class="social-links__item">
            <NuxtLink :to="social.link">
                <NuxtIcon v-if="social.class === 'facebook'" :name="`ri:facebook-box-fill`" />
                <NuxtIcon v-if="social.class === 'twitter'" :name="`ri:twitter-x-fill`" />
                <NuxtIcon v-if="social.class === 'linkedin'" :name="`ri:linkedin-box-fill`" />
            </NuxtLink>
        </li>
    </ul>
</template>

<script setup lang="ts">
const { links } = defineProps<{
    links: {
        link: string;
        class: string;
    }[];
}>();
</script>

<style lang="postcss" scoped>
@import 'assets/css/logos.css';

.social-links {
    @apply flex items-center gap-1.5;

    .iconify {
        @apply h-6 w-6;
        &:hover {
            @apply bg-gray-400;
        }
    }
}
</style>
